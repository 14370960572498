import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppointmentType } from '@dougs/accounting/onboarding/dto';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class CalendlyMockHttpService {
  constructor(private readonly http: HttpClient) {}

  mockAppointmentCreatedEvent(userId: number, appointmentType: AppointmentType): Observable<unknown> {
    return this.http.post(
      `${AppConfig.settings.legacyApiServerUrl}/test/users/${userId}/calendly/${appointmentType}`,
      null,
    );
  }

  mockAppointmentCanceledEvent(userId: number, appointmentType: AppointmentType): Observable<unknown> {
    return this.http.delete(
      `${AppConfig.settings.legacyApiServerUrl}/test/users/${userId}/calendly/${appointmentType}`,
    );
  }

  mockAppointmentRescheduledEvent(userId: number, appointmentType: AppointmentType): Observable<unknown> {
    return this.http.put(
      `${AppConfig.settings.legacyApiServerUrl}/test/users/${userId}/calendly/${appointmentType}`,
      null,
    );
  }
}
